import { Component, Inject, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { ThemeService } from '../../services/theme.service';

@Component({
    selector: 'app-create-theme',
    templateUrl: './create-theme.component.html',
    styleUrls: ['./create-theme.component.scss'],
    standalone: false
})
export class CreateThemeComponent implements OnInit, OnDestroy {
    theme: any;
    creating: boolean;

    headerLabel: string;
    footerLabel: string;

    private subscriptions = new Subscription();

    @Output() openErrorDialog: EventEmitter<string> = new EventEmitter();

    constructor(
        public dialogRef: MatDialogRef<CreateThemeComponent>,
        private themeService: ThemeService,
        @Inject(MAT_DIALOG_DATA) private data: any
    ) {}

    ngOnInit() {
        this.theme = {};
        this.theme.title = '';
        this.theme.forumid = this.data.instanceid;

        this.creating = false;

        this.headerLabel = 'Nouveau theme';
        this.footerLabel = 'Créer le theme';
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    canCreateTheme(): Boolean {
        return this.theme.title.length > 0;
    }

    /**
     * Crée/Met à jour le forum
     */
    createTheme() {
        if (this.canCreateTheme() && !this.creating) {
            this.creating = true;
            this.subscriptions.add(
                this.themeService.createTheme(this.theme).subscribe(
                    (data) => {
                        this.themeService.emitCreatedTheme(data);
                        this.closeDialog();
                    },
                    (error: HttpErrorResponse) => {
                        this.openErrorDialog.emit(error.error.userMessage);
                    }
                )
            );
        }
    }

    /**
     * Ferme la fenêtre d'enregistrement à un forum
     */
    closeDialog() {
        this.dialogRef.close();
    }
}
