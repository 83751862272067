import { Component, HostListener, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { ForumService } from './services/forum.service';
import { LoginService } from './services/login.service';
import { ConfigService } from './services/config.service';
import { DialogService } from './services/dialog.service';

import { isMobile } from 'mobile-device-detect';

/**
 * Composant principal de l'application
 */
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent implements OnInit, OnDestroy {
    subscriptions = new Subscription();

    currentForum: number;
    mouseMoved: boolean;
    isPanelOpen = true;
    loading = true;

    constructor(
        private forumService: ForumService,
        private loginService: LoginService,
        private configService: ConfigService,
        private dialogService: DialogService
    ) {}

    @HostListener('window:message', ['$event']) onMessage(event: any) {
        if (event.data.logout) {
            window.location.href = event.data.logout;
        } else if (event.data.structureid) {
            this.loginService.setLastStructure(event.data);
        } else if (event.data === 'getURL') {
            this.subscriptions.add(
                this.loginService.getCurrent().subscribe((data: any) => {
                    if (data === 0) {
                        window.location.href = this.configService.getRedirectEndPoint();
                    } else {
                        if (isMobile) {
                            if (this.loading) {
                                this.dialogService.openErrorDialog(
                                    "Ce site n'est pas optimisé pour un usage mobile, préférez une navigation sur ordinateur"
                                );
                            }
                        }
                        this.loading = false;
                    }
                })
            );
        } else if (event.data === 'refresh') {
            this.forumService.emitRefreshForums();
        }
    }

    @HostListener('window:mousemove', ['$event']) onMouseMoved(event: any) {
        this.mouseMoved = true;
    }

    ngOnInit() {
        this.mouseMoved = false;
        this.pingGetCurrent();
        this.loginService.initCheckConnectedInterval();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    /**
     * @param {number} event L'id d'un forum
     * Affiche le forum dont l'id est passé en paramètre dans la vue principal
     */
    updateCurrentForum(event: number) {
        this.currentForum = event;
    }

    showCarousel() {
        return this.currentForum === undefined;
    }

    toggleNav() {
        this.isPanelOpen = !this.isPanelOpen;
    }

    isLoading() {
        return this.loading;
    }

    /**
     * Effectue un appel à getCurrentUser toute les 20 minutes, si l'utilisateur a déplacé au moins une fois sa souris dans les dernières 20 minutes.
     * Cela permet de s'assurer que l'utilisateur ne sera pas déconnecté du serveur pour inactivité.
     */
    pingGetCurrent() {
        setInterval(() => {
            if (this.mouseMoved) {
                this.subscriptions.add(
                    this.loginService.getCurrentUserForum().subscribe((data: any) => {
                        this.mouseMoved = false;
                    })
                );
            }
        }, 1000 * 20 * 60);
    }
}
