import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Subject, Observable } from 'rxjs';
import { switchMap, share } from 'rxjs/operators';

import { DialogService } from '../../../services/dialog.service';
import { PermissionService } from '../../../services/permission.service';
import { ForumService } from '../../../services/forum.service';
import { ThemeService } from '../../../services/theme.service';
import { SubjectService } from '../../../services/subject.service';

import { Forum } from '../../../structures/forum';
import { Theme } from '../../../structures/theme';
import { ForumSubject } from '../../../structures/subject';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-forum',
    templateUrl: './forum.component.html',
    styleUrls: ['./forum.component.scss'],
    standalone: false
})
export class ForumComponent implements OnInit, OnDestroy {
    routeParam$: Subscription;
    private subscriptions = new Subscription();

    searchTerm: string;

    forum: Forum;
    forumReady: boolean;

    creatorSelectorOpened: boolean;

    updateForum: Subject<Forum> = new Subject();

    constructor(
        private dialogService: DialogService,
        private permissionService: PermissionService,
        private forumService: ForumService,
        private themeService: ThemeService,
        private subjectService: SubjectService,

        private route: ActivatedRoute,
        private router: Router
    ) {}

    ngOnInit() {
        this.subscriptions.add(
            this.forumService.getSearchTerm().subscribe((searchTerm: string) => {
                this.searchTerm = searchTerm;
                this.refreshForum(this.searchTerm);
            })
        );

        this.subscriptions.add(
            this.forumService.refreshCurrentForum.subscribe((searchTerm: string) => {
                if (this.forum && this.router.url === '/forum/' + this.forum.id) {
                    this.refreshForum(searchTerm);
                }
            })
        );

        this.subscriptions.add(
            this.themeService.movedUpTheme.subscribe((theme: Theme) => {
                const index = this.forum.themes.indexOf(theme);
                const firstElement = this.forum.themes[index - 1];
                const secondElement = this.forum.themes[index];

                this.forum.themes.splice(index - 1, 2, secondElement, firstElement);
            })
        );

        this.subscriptions.add(
            this.themeService.movedDownTheme.subscribe((theme: Theme) => {
                const index = this.forum.themes.indexOf(theme);
                const firstElement = this.forum.themes[index];
                const secondElement = this.forum.themes[index + 1];

                this.forum.themes.splice(index, 2, secondElement, firstElement);
            })
        );

        this.subscriptions.add(
            this.themeService.createdTheme.subscribe((theme: Theme) => {
                this.forum.themes.push(theme);
            })
        );

        this.subscriptions.add(
            this.themeService.deletedTheme.subscribe((themeId: number) => {
                let index: any;
                for (const i in this.forum.themes) {
                    if (this.forum.themes[i].id === themeId) {
                        index = i;
                    }
                }
                this.forum.themes.splice(index, 1);
            })
        );

        this.subscriptions.add(
            this.subjectService.createdSubjectInForum.subscribe((subject: ForumSubject) => {
                subject.views = 0;
                this.forum.subjects.push(subject);
            })
        );

        this.subscriptions.add(
            this.subjectService.deletedSubject.subscribe((subjectId: number) => {
                let index: any;
                for (const i in this.forum.subjects) {
                    if (this.forum.subjects[i].id === subjectId) {
                        index = i;
                    }
                }
                this.forum.subjects.splice(index, 1);
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    /**
     * @param {any} data Un objet représentant le forum
     * Initialise le forum
     */
    refreshForum(searchTerm: string) {
        this.forumReady = false;
        if (this.routeParam$) {
            this.routeParam$.unsubscribe();
        }
        this.routeParam$ = this.route.paramMap
            .pipe(
                switchMap((params: ParamMap) =>
                    this.forumService.getForum(
                        parseInt(params.get('id'), 10),
                        searchTerm,
                        undefined
                    )
                )
            )
            .subscribe((data: any) => {
                this.forum = data;
                this.forumReady = true;
                this.updateForum.next(this.forum);
            });
        this.subscriptions.add(this.routeParam$);
    }

    isForumReady(): boolean {
        return this.forumReady;
    }

    getThemesCount(): number {
        return this.forum.themes.length;
    }

    getSubthemesCount(): number {
        return this.forum.subthemes.length;
    }

    getPinnedSubjects(): Array<ForumSubject> {
        return this.forum.subjects.filter(function (subject) {
            return subject.pinned;
        });
    }

    getPinnedSubjectsCount(): number {
        return this.forum.subjects.filter(function (subject) {
            return subject.pinned;
        }).length;
    }

    getRegularSubjects(): Array<ForumSubject> {
        return this.forum.subjects.filter(function (subject) {
            return !subject.pinned;
        });
    }

    getRegularSubjectsCount(): number {
        return this.forum.subjects.filter(function (subject) {
            return !subject.pinned;
        }).length;
    }

    canCreateSubject(): Boolean {
        return this.permissionService.canCreateSubjectInForum(this.forum);
    }

    canCreateTheme(): Boolean {
        return this.permissionService.canCreateTheme(this.forum);
    }

    openDialogCreateSubject() {
        this.creatorSelectorOpened = false;
        this.dialogService.openCreateSubjectDialog(this.forum.id, 'forum');
    }

    openDialogCreateTheme() {
        this.creatorSelectorOpened = false;
        this.dialogService.openCreateThemeDialog(this.forum.id);
    }

    openCreatorSelector(): void {
        this.creatorSelectorOpened = !this.creatorSelectorOpened;
    }

    closeCreatorSelector(): void {
        this.creatorSelectorOpened = false;
    }

    isCreatorSelectorOpened(): boolean {
        return this.creatorSelectorOpened;
    }

    getTooltipText(): string {
        if (this.canCreateTheme()) {
            return 'Créer un nouveau thème/sujet';
        } else {
            return 'Créer un nouveau sujet';
        }
    }
}
