import { Component, OnInit, Inject, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { ForumService } from '../../services/forum.service';
import { UsersService } from '../../services/users.service';
import { LoginService } from '../../services/login.service';

/**
 * Composant gérant l'affichage de la fenêtre d'enregistrement à un forum
 */
@Component({
    selector: 'app-subscribe-forum',
    templateUrl: './subscribe-forum.component.html',
    styleUrls: ['./subscribe-forum.component.scss'],
    standalone: false
})
export class SubscribeForumComponent implements OnInit, OnDestroy {
    @Output() openErrorDialog: EventEmitter<string> = new EventEmitter();

    constructor(
        public dialogRef: MatDialogRef<SubscribeForumComponent>,
        private forumService: ForumService,
        private usersService: UsersService,
        private loginService: LoginService,
        @Inject(MAT_DIALOG_DATA) private data: any
    ) {}

    searchPublicForums$: Subscription;
    getPublicForumsCount$: Subscription;
    subscribeForums$: Subscription;
    getForum$: Subscription;
    private subscriptions = new Subscription();

    LIMIT = 30;

    forums: any;
    forumsCount: number;
    currentPage: number;

    creationDone: boolean;

    structures: Array<any>;
    selectedStructure: any;

    subscribedForums: Array<number>;

    searchTerm: string;

    ngOnInit() {
        this.creationDone = false;
        this.forums = [];
        this.subscribedForums = [];
        this.currentPage = -1;

        this.subscriptions.add(
            this.usersService.getStructures().subscribe(
                (data) => {
                    this.structures = data.data.structures.map((structure: any) => {
                        return {
                            key: structure.id,
                            title: structure.name
                        };
                    });
                    this.structures.unshift({ title: 'Administration nationale', key: 0 });
                    if (this.loginService.isNationalAdmin()) {
                        this.selectedStructure = this.loginService.getLastStructure();
                    } else {
                        this.selectedStructure = {
                            name: this.loginService.getUser().structurename,
                            id: this.loginService.getUser().structureid
                        };
                    }
                    this.refreshData();
                },
                (error: HttpErrorResponse) => {
                    this.openErrorDialog.emit(error.error.userMessage);
                }
            )
        );

        this.initRoutes();

        this.subscriptions.add(
            this.loginService.updateStructure.subscribe((data) => {
                this.selectedStructure = data;
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    /**
     * Indique si le menu de sélection de structure doit s'afficher pour l'utilisateur actuel
     * @returns {Boolean} True si le menu doit s'afficher, false dans le cas contraire
     */
    showStructuresDropdown(): Boolean {
        return this.loginService.isNationalAdmin();
    }

    /**
     * Rafraichit la liste des forums
     */
    nextPage() {
        const params: any = {};
        this.currentPage++;

        params.structureid = this.selectedStructure.id;
        params.search = this.searchTerm ? this.searchTerm : '';
        params.offset = this.currentPage * this.LIMIT;
        params.limit = this.LIMIT;

        if (this.selectedStructure.id !== undefined) {
            if (this.searchPublicForums$) {
                this.searchPublicForums$.unsubscribe();
            }
            this.searchPublicForums$ = this.forumService.searchPublicForums(params).subscribe(
                (data: any) => {
                    this.forums = this.forums.concat(data);
                },
                (error: HttpErrorResponse) => {
                    this.openErrorDialog.emit(error.error.userMessage);
                }
            );
            this.subscriptions.add(this.searchPublicForums$);
        }
    }

    updateStructure($event: any) {
        this.structures = $event;
        for (const i in this.structures) {
            if (this.structures[i].selected) {
                this.selectedStructure = {
                    id: this.structures[i].key,
                    name: this.structures[i].title
                };
            }
        }
        this.refreshData();
    }

    /**
     * rafraichit les donnees lors d'une recherche, scroll de bas de page ou changement de seclectedStructure.
     */
    refreshData() {
        if (this.selectedStructure) {
            if (this.selectedStructure.id !== undefined && this.selectedStructure.id !== 0) {
                this.loginService.updateLastStructure(this.selectedStructure);
            }
            this.forums = [];
            this.currentPage = -1;
            this.nextPage();
            this.getPublicForumsNumber();
        }
    }
    /**
     * @param {any} data Un objet contenant les champ suivants : id, selected
     * Ajoute/supprime un forum à la liste des forums sélectionnés
     */
    addToSubscribedForum(data: any) {
        if (data.selected) {
            this.subscribedForums.push(data.id);
        } else {
            for (const i in this.subscribedForums) {
                if (this.subscribedForums[i] === data.id) {
                    this.subscribedForums.splice(parseInt(i, 10), 1);
                }
            }
        }
    }

    /**
     * @param {any} forum Un objet représentant un forum
     * Indique si le forum passé en paramètre est sélectionné ou non
     * @returns {Boolean} True si le forum est sélectionné, false dans le cas contraire
     */
    isForumSelected(forum: any): Boolean {
        for (const i in this.subscribedForums) {
            if (this.subscribedForums[i] === forum.id) {
                return true;
            }
        }
        return false;
    }

    /**
     * Initialise le nombre de forums auxquels l'administrateur peut s'inscrire
     */
    getPublicForumsNumber() {
        const params: any = {};

        params.structureid = this.selectedStructure.id;
        params.search = this.searchTerm ? this.searchTerm : '';

        if (params.structureid !== undefined) {
            if (this.getPublicForumsCount$) {
                this.getPublicForumsCount$.unsubscribe();
            }
            this.getPublicForumsCount$ = this.forumService.getPublicForumsCount(params).subscribe(
                (data: any) => {
                    this.forumsCount = data.count;
                },
                (error: HttpErrorResponse) => {
                    this.openErrorDialog.emit(error.error.userMessage);
                }
            );
            this.subscriptions.add(this.getPublicForumsCount$);
        }
    }

    /**
     * Indique si le bouton de validation de l'inscription doit être activé ou non
     * @returns {Boolean} True si le bouton d'inscription doit etre activé, false dans le cas contraire
     */
    canSubscribeToForum(): boolean {
        return this.subscribedForums.length > 0;
    }

    /**
     * S'enregistre aux forums présent dans la liste des forums sélectionnés
     */
    subscribeToForums() {
        if (this.canSubscribeToForum()) {
            this.creationDone = true;

            const body = {
                reservations: this.subscribedForums
            };

            if (this.subscribeForums$) {
                this.subscribeForums$.unsubscribe();
            }
            this.subscribeForums$ = this.forumService.subscribeForums(body).subscribe(
                (data: any) => {
                    this.forumService.emitRefreshForums();
                    this.closeDialog();
                },
                (error: HttpErrorResponse) => {
                    this.openErrorDialog.emit(error.error.userMessage);
                }
            );
            this.subscriptions.add(this.subscribeForums$);
        }
    }

    /**
     * Ferme la fenêtre d'enregistrement à un forum
     */
    closeDialog() {
        this.dialogRef.close();
    }

    /**
     * Initialise les forums sélectionnés selon les élements passé en paramètre
     */
    initRoutes() {
        if (this.data.forumId) {
            if (this.getForum$) {
                this.getForum$.unsubscribe();
            }
            this.getForum$ = this.forumService.getForum(this.data.forumId).subscribe(
                () => {
                    this.addToSubscribedForum({ selected: true, id: this.data.forumId });
                },
                (error: HttpErrorResponse) => {
                    this.openErrorDialog.emit(error.error.userMessage);
                }
            );
            this.subscriptions.add(this.getForum$);
        }
    }
}
