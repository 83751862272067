import {
    Component,
    OnInit,
    HostListener,
    Input,
    ViewChild,
    ElementRef,
    OnDestroy
} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { DialogService } from '../../../../services/dialog.service';
import { SubthemeService } from '../../../../services/subtheme.service';
import { PermissionService } from '../../../../services/permission.service';

import { Forum } from '../../../../structures/forum';
import { Theme } from '../../../../structures/theme';
import { Subtheme } from '../../../../structures/subtheme';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-subtheme-entry',
    templateUrl: './subtheme-entry.component.html',
    styleUrls: ['./subtheme-entry.component.scss'],
    standalone: false
})
export class SubthemeEntryComponent implements OnInit, OnDestroy {
    @Input() subtheme: Subtheme;
    @Input() theme: Theme;
    @Input() forum: Forum;

    @Input() inbox: boolean;
    @ViewChild('inputTitle') inputTitle: ElementRef;

    initialTheme: string;
    editing: boolean;
    formattedDate: Date;

    private subscriptions = new Subscription();

    constructor(
        private dialogService: DialogService,
        private subthemeService: SubthemeService,
        private permissionService: PermissionService
    ) {}

    @HostListener('window:click', ['$event']) onMessage(event: any) {
        this.editing = false;
    }

    ngOnInit() {
        this.initialTheme = this.subtheme.title;
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    isInbox() {
        return this.inbox;
    }

    isViewedSubtheme() {
        return this.subtheme.viewed;
    }

    /**
     * Indique l'icône à afficher selon le type/rôle de l'élement
     * @returns {string} La classe CSS à appliquer afin d'afficher l'icône correspondante
     */
    getRoleIcon(): string {
        if (this.subtheme.last.author.roles.learner) {
            return 'icon-apprenant';
        } else if (this.subtheme.author.roles.prospect) {
            return 'icon-apprenant-prospect';
        } else if (this.subtheme.last.author.roles.internalTeacher) {
            return 'icon-formateurinterne';
        } else if (this.subtheme.last.author.roles.externalTeacher) {
            return 'icon-formateurexterne';
        } else if (this.subtheme.last.author.roles.siteTeacher) {
            return 'icon-icon-formateur-site';
        } else if (this.subtheme.last.author.roles.corporationTeacher) {
            return 'icon-FormateurEntreprise';
        } else if (this.subtheme.last.author.roles.tuteurentreprise) {
            return 'icon-tuteurentreprise';
        } else if (this.subtheme.last.author.roles.localAdmin) {
            return 'icon-adminlocal';
        } else if (this.subtheme.last.author.roles.nationalAdmin) {
            return 'icon-adminnational';
        }
    }

    getPostCount(): number {
        if (this.subtheme.postscount) {
            return this.subtheme.postscount;
        }
    }

    showIcons(): Boolean {
        return this.canMoveSubtheme() || this.canEditSubtheme() || this.canDeleteSubtheme();
    }

    canMoveSubtheme(): boolean {
        return this.permissionService.canMoveSubtheme(this.forum);
    }

    canEditSubtheme(): boolean {
        return this.permissionService.canEditSubtheme(this.forum);
    }

    canDeleteSubtheme(): boolean {
        return this.permissionService.canDeleteSubtheme(this.forum);
    }

    getSubthemeLastMessageAuthor(): string {
        if (this.subtheme.last) {
            return (
                this.subtheme.last.author.lastname.toUpperCase() +
                ' ' +
                this.subtheme.last.author.firstname
            );
        }
    }

    getSubthemeLastMessageDate(): Date {
        if (this.subtheme.last) {
            return new Date(this.subtheme.last.timemodified);
        }
    }

    moveUpSubtheme($event: Event) {
        $event.stopPropagation();
        if (this.canMoveUpSubtheme()) {
            this.subscriptions.add(
                this.subthemeService.moveUpSubtheme(this.subtheme.id).subscribe(
                    (data: any) => {
                        this.subthemeService.emitMovedUpSubtheme(this.subtheme);
                    },
                    (error: HttpErrorResponse) => {
                        this.dialogService.openErrorDialog(error.error.userMessage);
                    }
                )
            );
        }
    }

    canMoveUpSubtheme(): boolean {
        if (!this.theme) {
            return false;
        }
        return this.theme.subthemes.indexOf(this.subtheme) > 0;
    }

    moveDownSubtheme($event: Event) {
        $event.stopPropagation();
        if (this.canMoveDownSubtheme()) {
            this.subscriptions.add(
                this.subthemeService.moveDownSubtheme(this.subtheme.id).subscribe(
                    (data: any) => {
                        this.subthemeService.emitMovedDownSubtheme(this.subtheme);
                    },
                    (error: HttpErrorResponse) => {
                        this.dialogService.openErrorDialog(error.error.userMessage);
                    }
                )
            );
        }
    }

    canMoveDownSubtheme(): boolean {
        if (!this.theme) {
            return false;
        }
        return this.theme.subthemes.indexOf(this.subtheme) < this.theme.subthemes.length - 1;
    }

    editSubtheme($event: Event) {
        $event.stopPropagation();
        this.editing = true;
        setTimeout(() => {
            this.inputTitle.nativeElement.focus();
        });
    }

    deleteSubtheme($event: Event) {
        $event.stopPropagation();
        this.dialogService.openWarningDialog('DELETE_SUBTHEME', this.subtheme.id);
    }

    cancelEditSubtheme() {
        this.editing = false;
        this.subtheme.title = this.initialTheme;
    }

    renameSubtheme($event: Event) {
        $event.stopPropagation();
        if (this.subtheme.title.length > 0) {
            this.subscriptions.add(
                this.subthemeService.updateSubtheme(this.subtheme).subscribe(() => {
                    this.editing = false;
                    this.initialTheme = this.subtheme.title;
                })
            );
        } else {
            this.editing = false;
            this.subtheme.title = this.initialTheme;
        }
    }
}
