import { Component, Inject, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';

import { SubthemeService } from '../../services/subtheme.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-create-subtheme',
    templateUrl: './create-subtheme.component.html',
    styleUrls: ['./create-subtheme.component.scss'],
    standalone: false
})
export class CreateSubthemeComponent implements OnInit, OnDestroy {
    subtheme: any;
    creating: boolean;

    headerLabel: string;
    footerLabel: string;

    private subscriptions = new Subscription();

    @Output() openErrorDialog: EventEmitter<string> = new EventEmitter();

    constructor(
        public dialogRef: MatDialogRef<CreateSubthemeComponent>,
        private subthemeService: SubthemeService,
        @Inject(MAT_DIALOG_DATA) private data: any
    ) {}

    ngOnInit() {
        this.subtheme = {};
        this.subtheme.title = '';
        this.subtheme.themeid = this.data.instanceid;

        this.creating = false;

        this.headerLabel = 'Nouveau sous-theme';
        this.footerLabel = 'Créer le sous-theme';
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    canCreateSubtheme(): Boolean {
        return this.subtheme.title.length > 0;
    }

    /**
     * Crée/Met à jour le forum
     */
    createSubtheme() {
        if (this.canCreateSubtheme() && !this.creating) {
            this.creating = true;
            this.subscriptions.add(
                this.subthemeService.createSubtheme(this.subtheme).subscribe(
                    (data) => {
                        this.subthemeService.emitCreatedSubtheme(data);
                        this.closeDialog();
                    },
                    (error: HttpErrorResponse) => {
                        this.openErrorDialog.emit(error.error.userMessage);
                    }
                )
            );
        }
    }

    /**
     * Ferme la fenêtre d'enregistrement à un forum
     */
    closeDialog() {
        this.dialogRef.close();
    }
}
