import { Component, OnInit, Inject, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { ForumService } from '../../services/forum.service';

@Component({
    selector: 'app-members',
    templateUrl: './members.component.html',
    styleUrls: ['./members.component.scss'],
    standalone: false
})
export class MembersComponent implements OnInit, OnDestroy {
    @Output() openErrorDialog: EventEmitter<string> = new EventEmitter();

    forum: any;

    groupNumber: number;
    learnerNumber: number;
    teacherNumber: number;
    roleNumber: number;

    private subscriptions = new Subscription();

    constructor(
        public dialogRef: MatDialogRef<MembersComponent>,
        private forumService: ForumService,
        @Inject(MAT_DIALOG_DATA) private data: any
    ) {}

    ngOnInit() {
        this.subscriptions.add(
            this.forumService.getForum(this.data.forumId).subscribe((data: any) => {
                this.forum = data;
                const rolesTmp = [];
                let roleExisting: boolean;
                for (const i in this.forum.roles) {
                    if (this.forum.roles[i]) {
                        roleExisting = false;
                        for (const j in rolesTmp) {
                            if (rolesTmp[j].id === this.forum.roles[i].id) {
                                roleExisting = true;
                            }
                        }
                        if (!roleExisting) {
                            rolesTmp.push(this.forum.roles[i]);
                        }
                    }
                }
                this.forum.roles = rolesTmp;
                this.groupNumber = data.groups.length;
                this.roleNumber = data.roles.length;
                this.learnerNumber = 0;
                this.teacherNumber = 0;
                for (const i in data.users) {
                    if (data.users[i].roles.learner || data.users[i].roles.prospect) {
                        this.learnerNumber++;
                    } else {
                        this.teacherNumber++;
                    }
                }
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    closeDialog() {
        this.dialogRef.close();
    }
}
