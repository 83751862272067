import {
    Component,
    OnInit,
    AfterViewChecked,
    OnDestroy,
    ViewChild,
    ElementRef
} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { SubjectService } from '../../../services/subject.service';
import { DialogService } from '../../../services/dialog.service';
import { PostService } from '../../../services/post.service';
import { PermissionService } from '../../../services/permission.service';
import { LoginService } from '../../../services/login.service';

import { ForumSubject } from '../../../structures/subject';
import { Post } from '../../../structures/post';

import { Subscription } from 'rxjs';

@Component({
    selector: 'app-subject',
    templateUrl: './subject.component.html',
    styleUrls: ['./subject.component.scss'],
    standalone: false
})
export class SubjectComponent implements OnInit, OnDestroy, AfterViewChecked {
    @ViewChild('scrollBarContainer') scrollbarContainer: ElementRef;
    @ViewChild('input') input: ElementRef;
    routeParam$: Subscription;
    createPost$: Subscription;
    createAttachment$: Subscription;
    private subscriptions = new Subscription();

    subject: ForumSubject;
    subjectReady: boolean;
    files: Array<any>;
    answer: string;
    quote: Post;
    scroll: boolean;
    posting: boolean;

    updateSubject: Subject<ForumSubject> = new Subject();

    constructor(
        private subjectService: SubjectService,
        private dialogService: DialogService,
        private postService: PostService,
        private permissionService: PermissionService,
        private loginService: LoginService,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.files = [];
        this.answer = '';
        this.scroll = false;
        this.posting = false;

        this.routeParam$ = this.route.paramMap
            .pipe(
                switchMap((params: ParamMap) =>
                    this.subjectService.getSubject(parseInt(params.get('id'), 10))
                )
            )
            .subscribe((data: any) => {
                this.subject = data;
                this.scroll = true;
                this.updateSubject.next(this.subject);
            });
        this.subscriptions.add(this.routeParam$);

        this.subscriptions.add(
            this.subjectService.refreshCurrentSubject.subscribe((searchTerm) => {
                this.refreshSubject(searchTerm);
            })
        );

        this.subscriptions.add(
            this.postService.deletedPost.subscribe((postId: number) => {
                for (const i in this.subject.posts) {
                    if (this.subject.posts[i].id === postId) {
                        this.subject.posts[i].deleted = true;
                        this.subject.posts[i].deleterid = this.loginService.getUser().id;
                    }
                }
            })
        );

        this.subscriptions.add(
            this.postService.updatedPost.subscribe((post: Post) => {
                for (const i in this.subject.posts) {
                    if (this.subject.posts[i].id === post.id) {
                        this.subject.posts[i] = post;
                    }
                }
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    ngAfterViewChecked() {
        if (this.scroll) {
            (this.scrollbarContainer.nativeElement as HTMLElement).scrollTo({
                top: (this.scrollbarContainer.nativeElement as HTMLElement).scrollHeight
            });
            this.scroll = false;
        }
    }

    refreshSubject(searchTerm: string) {
        this.subjectReady = false;
        if (this.routeParam$) {
            this.routeParam$.unsubscribe();
        }
        this.routeParam$ = this.route.paramMap
            .pipe(
                switchMap((params: ParamMap) =>
                    this.subjectService.getSubject(parseInt(params.get('id'), 10), searchTerm)
                )
            )
            .subscribe((data: any) => {
                this.subject = data;
                this.subjectReady = true;
                this.updateSubject.next(this.subject);
            });
        this.subscriptions.add(this.routeParam$);
    }

    updateAnswer(value: string) {
        this.answer = value.replace(/(<br><br>)$/g, '');
    }

    sendPost() {
        if (!this.answer || this.posting) {
            (this.input.nativeElement as HTMLElement).innerHTML = '';
            return;
        }
        this.updateAnswer((this.input.nativeElement as HTMLElement).innerHTML);
        this.posting = true;

        const body: any = {};
        body.title = '';
        body.message = this.answer;
        body.subjectid = this.subject.id;
        if (this.quote) {
            body.quotepostid = this.quote.id;
        }
        const quote = { ...this.quote }; // to access this.quote value inside the subscribe callback
        if (this.createPost$) {
            this.createPost$.unsubscribe();
        }
        this.createPost$ = this.postService
            .createPost(body)
            .pipe()
            .subscribe(
                (post: Post) => {
                    if (quote.message) {
                        post = {
                            // adding quote to the post so as to integrate immediately the quote in the page
                            ...post,
                            quote: quote
                        };
                    }

                    if (this.files.length > 0) {
                        for (const file of this.files) {
                            file.extension = file.name.split('.')[file.name.split('.').length - 1];
                            if (this.createAttachment$) {
                                this.createAttachment$.unsubscribe();
                            }
                            this.createAttachment$ = this.postService
                                .createAttachment(post.id, file)
                                .subscribe(
                                    () => {
                                        if (file === this.files[this.files.length - 1]) {
                                            post.attachments = this.files;
                                            this.files = [];
                                            this.clearPost(post);
                                        }
                                    },
                                    (error: HttpErrorResponse) => {
                                        this.dialogService.openErrorDialog(error.error.userMessage);
                                    }
                                );
                            this.subscriptions.add(this.createAttachment$);
                        }
                        setTimeout(() => (this.scroll = true), 200);
                    } else {
                        this.clearPost(post);
                    }
                },
                (error: HttpErrorResponse) => {
                    this.dialogService.openErrorDialog(error.error.userMessage);
                }
            );
        this.subscriptions.add(this.createPost$);
    }

    clearPost(post: Post) {
        this.subject.posts.push(post);
        this.posting = false;
        (this.input.nativeElement as HTMLElement).innerHTML = '';
        this.answer = '';
        this.quote = null;
        this.scroll = true;
    }

    quotePost(post: Post) {
        this.quote = post;
        this.scroll = true;
    }

    removeQuote() {
        this.quote = null;
    }

    canCreatePost(): Boolean {
        if (this.subject) {
            return this.permissionService.canCreatePost(this.subject.hierarchy.forum);
        }
    }

    openUploadFilesDialog() {
        this.subscriptions.add(
            this.dialogService.openUploadFilesDialog().subscribe((data: Array<File>) => {
                if (data !== undefined) {
                    this.files = this.files.concat(data);
                    this.scroll = true;
                }
            })
        );
    }

    removeAttachment(index: number) {
        if (this.files.length) {
            this.files.splice(index, 1);
        }
    }

    getFormattedSize(size: number): string {
        if (size > 1000000) {
            return Math.floor(size / 1000000) + 'Mo';
        } else if (size > 1000) {
            return Math.floor(size / 1000) + 'Ko';
        } else {
            return Math.floor(size) + 'o';
        }
    }
}
