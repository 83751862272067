import {
    Component,
    ElementRef,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';

import { DialogService } from '../../../../services/dialog.service';
import { PermissionService } from '../../../../services/permission.service';
import { SubjectService } from '../../../../services/subject.service';

import { Subscription } from 'rxjs';
import { Forum } from '../../../../structures/forum';
import { ForumSubject } from '../../../../structures/subject';

/**
 * Composant gérant l'affichage des groupes/utilisateurs dans la vue principal
 */
@Component({
    selector: 'app-subject-entry',
    templateUrl: './subject-entry.component.html',
    styleUrls: ['./subject-entry.component.scss'],
    standalone: false
})
export class SubjectEntryComponent implements OnInit, OnDestroy {
    @Input() subject: ForumSubject;
    @Input() forum: Forum;
    @ViewChild('inputTitle') inputTitle: ElementRef;

    editing: boolean;
    initialSubject: string;
    updateSubject$: Subscription;
    private subscriptions = new Subscription();

    @HostListener('window:click', ['$event']) onMessage(event: any) {
        this.editing = false;
    }

    constructor(
        private dialogService: DialogService,
        private subjectService: SubjectService,
        private permissionService: PermissionService
    ) {}

    ngOnInit() {
        this.initialSubject = this.subject.title;
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    /**
     * Indique l'icône à afficher selon le type/rôle de l'élement
     * @returns {string} La classe CSS à appliquer afin d'afficher l'icône correspondante
     */
    getRoleIcon(): string {
        if (this.subject.last.author.roles.learner) {
            return 'icon-apprenant';
        } else if (this.subject.author.roles.prospect) {
            return 'icon-apprenant-prospect';
        } else if (this.subject.last.author.roles.internalTeacher) {
            return 'icon-formateurinterne';
        } else if (this.subject.last.author.roles.externalTeacher) {
            return 'icon-formateurexterne';
        } else if (this.subject.last.author.roles.siteTeacher) {
            return 'icon-icon-formateur-site';
        } else if (this.subject.last.author.roles.corporationTeacher) {
            return 'icon-FormateurEntreprise';
        } else if (this.subject.last.author.roles.tuteurentreprise) {
            return 'icon-tuteurentreprise';
        } else if (this.subject.last.author.roles.localAdmin) {
            return 'icon-adminlocal';
        } else if (this.subject.last.author.roles.nationalAdmin) {
            return 'icon-adminnational';
        }
    }

    showIcons(): Boolean {
        return (
            this.canPinSubject() ||
            this.canMoveSubject() ||
            this.canEditSubject() ||
            this.canDeleteSubject()
        );
    }

    canPinSubject(): Boolean {
        return this.permissionService.canPinSubject(this.forum);
    }

    canMoveSubject(): Boolean {
        return this.permissionService.canMoveSubject(this.forum, this.subject);
    }

    canEditSubject(): Boolean {
        return this.permissionService.canEditSubject(this.forum, this.subject);
    }

    canDeleteSubject(): Boolean {
        return this.permissionService.canDeleteSubject(this.forum, this.subject);
    }

    getMessageCount(): number {
        if (this.subject) {
            return this.subject.postscount;
        }
    }

    getViewCount(): number {
        if (this.subject) {
            return this.subject.views;
        }
    }

    getSubjectLastMessageAuthor(): string {
        if (this.subject.last) {
            return (
                this.subject.last.author.lastname.toUpperCase() +
                ' ' +
                this.subject.last.author.firstname
            );
        }
    }

    getSubjectLastMessageDate(): Date {
        if (this.subject.last) {
            return new Date(this.subject.last.timemodified);
        }
    }

    isPinnedSubject(): boolean {
        return this.subject.pinned;
    }

    isViewedSubject(): boolean {
        return this.subject.viewed;
    }

    pinSubject($event: Event) {
        $event.stopPropagation();
        this.subscriptions.add(
            this.subjectService
                .updateSubject({ ...this.subject, pinned: !this.subject.pinned })
                .subscribe({
                    next: () => {
                        this.subject.pinned = !this.subject.pinned;
                    },
                    error: null,
                    complete: null
                })
        );
    }

    moveSubject($event: Event) {
        $event.stopPropagation();
        this.dialogService.openMoveSubjectDialog(this.subject, this.forum.id);
    }

    deleteSubject($event: Event) {
        $event.stopPropagation();
        this.dialogService.openWarningDialog('DELETE_SUBJECT', this.subject.id);
    }

    editSubject($event: Event) {
        $event.stopPropagation();
        this.editing = true;
        setTimeout(() => {
            this.inputTitle.nativeElement.focus();
        });
    }

    renameSubject($event: Event) {
        $event.stopPropagation();
        if (this.subject.title.length > 0) {
            if (this.updateSubject$) {
                this.updateSubject$.unsubscribe();
            }
            this.updateSubject$ = this.subjectService.updateSubject(this.subject).subscribe(() => {
                this.editing = false;
                this.initialSubject = this.subject.title;
            });
            this.subscriptions.add(this.updateSubject$);
        } else {
            this.editing = false;
            this.subject.title = this.initialSubject;
        }
    }

    cancelEditSubject() {
        this.editing = false;
        this.subject.title = this.initialSubject;
    }
}
