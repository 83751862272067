import { Component, OnInit, Inject, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { UsersService } from '../../services/users.service';
import { ForumService } from '../../services/forum.service';
import { LoginService } from '../../services/login.service';
import { Forum } from 'src/app/structures/forum';

@Component({
    selector: 'app-shared-forum',
    templateUrl: './shared-forum.component.html',
    styleUrls: ['./shared-forum.component.scss'],
    standalone: false
})
export class SharedForumComponent implements OnInit, OnDestroy {
    isLoaded = false;
    sharedForumsLoaded = false;
    sharedForums: Array<any>;
    adminForumsLoaded = false;
    adminForumsIdsList: Array<number>;
    forums: Array<any>;
    headerText: string;

    private subscriptions = new Subscription();

    @Output() openErrorDialog: EventEmitter<string> = new EventEmitter();

    constructor(
        public dialogRef: MatDialogRef<SharedForumComponent>,
        private forumService: ForumService,
        private usersService: UsersService,
        private loginService: LoginService,
        private router: Router,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    /**
     * Indique si le menu de sélection de structure doit s'afficher pour l'utilisateur actuel
     * @returns {Boolean} True si le menu doit s'afficher, false dans le cas contraire
     */
    showStructuresDropdown(): boolean {
        return this.loginService.isNationalAdmin();
    }

    openCreateForum() {
        const queryParams = [];

        queryParams['new'] = 'true';
        queryParams[this.data.type] = this.data.id;

        this.router.navigate(['.'], { queryParams: queryParams });

        this.dialogRef.close();
    }

    openSharedForum(forum: Forum): void {
        this.dialogRef.close(forum);
    }

    /**
     * Ferme la fenêtre actuelle
     */
    closeDialog() {
        this.dialogRef.close();
    }

    ngOnInit() {
        const params = {
            type: this.data.type,
            instanceid: this.data.id
        };

        this.subscriptions.add(
            this.forumService.getSharedForums(params).subscribe((data: any) => {
                this.sharedForums = data;
                this.sharedForumsLoaded = true;
                this.sharedForumsReconciliation();
            })
        );

        this.subscriptions.add(
            this.forumService.getForumsSubject().subscribe((data: any) => {
                if (data.mine && data.member && data.archived) {
                    this.adminForumsIdsList = [
                        ...data.mine.map((forum) => forum.id),
                        ...data.member.map((forum) => forum.id)
                        // ...data.archived.map(forum => forum.id),
                    ];
                    this.adminForumsLoaded = true;
                    this.sharedForumsReconciliation();
                }
            })
        );

        if (this.data.type === 'group') {
            this.headerText = `Forums partagés avec ce groupe :`;
        } else if (this.data.type === 'user') {
            this.headerText = `Forums partagés avec cet utilisateur :`;
        }
    }

    sharedForumsReconciliation() {
        if (this.adminForumsLoaded && this.sharedForumsLoaded) {
            this.forums = this.sharedForums.filter((call) =>
                this.adminForumsIdsList.find((id) => id === call.id)
            );
            if (this.forums.length === 0) {
                this.openCreateForum();
            }
            this.isLoaded = true;
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}
