import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    OnDestroy,
    ViewChild,
    ElementRef
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { DialogService } from '../../../services/dialog.service';
import { ConfigService } from '../../../services/config.service';
import { PostService } from '../../../services/post.service';
import { PermissionService } from '../../../services/permission.service';
import { LoginService } from '../../../services/login.service';

import { Post } from '../../../structures/post';
import { Forum } from '../../../structures/forum';
import { Attachment } from '../../../structures/attachment';

import { Subscription } from 'rxjs';

@Component({
    selector: 'app-post',
    templateUrl: './post.component.html',
    styleUrls: ['./post.component.scss'],
    standalone: false
})
export class PostComponent implements OnInit, OnDestroy {
    @ViewChild('input') input: ElementRef;
    @Input() post: Post;
    @Input() forum: Forum;
    @Input() isQuote: boolean;
    @Input() published: boolean;

    @Output() quote: EventEmitter<Post> = new EventEmitter();

    formattedDate: Date;
    formattedMessage: SafeHtml;
    lastLog: { fullName: string; fullDate: string; id: number } = null;

    fileToAdd: Array<File>;
    editionMode: boolean;
    answer: string;

    deleteAttachment$: Subscription;
    updatePost$: Subscription;
    createAttachment$: Subscription;
    private subscriptions = new Subscription();

    constructor(
        private dialogService: DialogService,
        private postService: PostService,
        private domSanitizer: DomSanitizer,
        private configService: ConfigService,
        private loginService: LoginService,
        private permissionService: PermissionService
    ) {}

    ngOnInit() {
        this.formattedDate = this.isQuote ? new Date(this.post.timemodified) : null;
        this.formattedMessage = this.domSanitizer.bypassSecurityTrustHtml(
            this.formatMessage(this.post.message)
        );
        this.editionMode = false;
        this.fileToAdd = [];
        this.answer = '';
        if (this.post.logs && this.post.logs.length) {
            const last = this.post.logs[this.post.logs.length - 1];
            const tmp: Date = new Date(last.timemodified);
            this.lastLog = {
                fullName: last.lastname.toUpperCase() + ' ' + last.firstname,
                fullDate: tmp.toLocaleDateString('fr-FR') + ' à ' + tmp.toLocaleTimeString('fr-FR'),
                id: parseInt(last.id, 10)
            };
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    getIconStyle(): string {
        if (this.post.author.roles.learner) {
            return 'icon-apprenant';
        } else if (this.post.author.roles.prospect) {
            return 'icon-apprenant-prospect';
        } else if (this.post.author.roles.internalTeacher) {
            return 'icon-formateurinterne';
        } else if (this.post.author.roles.externalTeacher) {
            return 'icon-formateurexterne';
        } else if (this.post.author.roles.siteTeacher) {
            return 'icon-icon-formateur-site';
        } else if (this.post.author.roles.corporationTeacher) {
            return 'icon-FormateurEntreprise';
        } else if (this.post.author.roles.tuteurentreprise) {
            return 'icon-tuteurentreprise';
        } else if (this.post.author.roles.localAdmin) {
            return 'icon-adminlocal';
        } else if (this.post.author.roles.nationalAdmin) {
            return 'icon-adminnational';
        }
    }

    getFormattedSize(size: number): String {
        if (size > 1000000) {
            return Math.floor(size / 1000000) + 'Mo';
        } else if (size > 1000) {
            return Math.floor(size / 1000) + 'Ko';
        } else {
            return Math.floor(size) + 'o';
        }
    }

    isImage(attachment: Attachment): boolean {
        return (
            ['jpg', 'jpeg', 'gif', 'png', 'apng', 'svg', 'bmp'].indexOf(
                attachment.extension.toLowerCase()
            ) > -1
        );
    }

    getAttachmentUrl(attachment: Attachment): string {
        return (
            this.configService.getEndPoint() +
            '/posts/' +
            this.post.id +
            '/attachments/' +
            attachment.name
        );
    }

    downloadAttachment(attachmentName: string) {
        return (
            this.configService.getEndPoint() +
            '/posts/' +
            this.post.id +
            '/attachments/' +
            encodeURIComponent(attachmentName)
        );
    }

    openPreview(attachment: Attachment) {
        this.dialogService.openPreviewImage(this.getAttachmentUrl(attachment));
    }

    deleteAttachment(attachmentName: string) {
        if (this.deleteAttachment$) {
            this.deleteAttachment$.unsubscribe();
        }
        this.deleteAttachment$ = this.postService
            .deleteAttachment(this.post.id, attachmentName)
            .subscribe((data: any) => {
                for (let i = 0; i < this.post.attachments.length; ++i) {
                    if (this.post.attachments[i].name === attachmentName) {
                        this.post.attachments.splice(i, 1);
                    }
                }
            });
        this.subscriptions.add(this.deleteAttachment$);
    }

    removeAttachment(attachmentName: string) {
        for (let i = 0; i < this.fileToAdd.length; i++) {
            if (this.fileToAdd[i].name === attachmentName) {
                this.fileToAdd.splice(i, 1);
            }
        }
    }

    openUploadFilesDialog() {
        this.subscriptions.add(
            this.dialogService.openUploadFilesDialog().subscribe((data: Array<File>) => {
                this.fileToAdd = this.fileToAdd.concat(data);
            })
        );
    }

    showIcons(): boolean {
        return this.canEditPost() || this.canDeletePost();
    }

    canEditPost(): boolean {
        return this.permissionService.canEditPost(this.forum, this.post);
    }

    canDeletePost(): boolean {
        return this.permissionService.canDeletePost(this.forum, this.post);
    }

    openDeletePostDialog() {
        this.dialogService.openWarningDialog('DELETE_POST', this.post.id);
    }

    setEdition() {
        this.editionMode = true;
        setTimeout(() => {
            let range: any, selection: any;
            if (document.createRange) {
                range = document.createRange();
                range.selectNodeContents(this.input.nativeElement);
                range.collapse(false);
                selection = window.getSelection();
                selection.removeAllRanges();
                selection.addRange(range);
            }
        });
    }

    stopEditing() {
        this.editionMode = false;
    }

    isEditing() {
        return this.editionMode;
    }

    isDeleted() {
        return this.post.deleted;
    }

    updateAnswer(value: string) {
        if (value.replace(/<div><br><\/div><div><br><\/div>/g, '') === '') {
            this.answer = '';
        } else {
            this.answer = value;
        }
    }

    updatePost() {
        this.updateAnswer((this.input.nativeElement as HTMLElement).innerHTML);
        if (!this.answer) {
            return;
        }

        const body: any = {};
        body.title = '';
        body.message = this.answer;

        this.updatePost$ = this.postService.updatePost(this.post.id, body).subscribe(() => {
            this.post.message = body.message;
            this.formattedMessage = this.domSanitizer.bypassSecurityTrustHtml(
                this.formatMessage(this.post.message)
            );
            if (this.fileToAdd.length) {
                for (const file of this.fileToAdd) {
                    this.createAttachment$ = this.postService
                        .createAttachment(this.post.id, file)
                        .subscribe(() => {
                            if (file === this.fileToAdd[this.fileToAdd.length - 1]) {
                                this.post.attachments = [...this.post.attachments, this.fileToAdd];
                                this.postService.emitUpdatedPost(this.post);
                                this.fileToAdd = [];
                                this.editionMode = false;
                                this.lastLog = {
                                    fullName:
                                        this.loginService.getUser().lastname.toUpperCase() +
                                        ' ' +
                                        this.loginService.getUser().firstname,
                                    fullDate:
                                        new Date().toLocaleDateString('fr-FR') +
                                        ' à ' +
                                        new Date().toLocaleTimeString('fr-FR'),
                                    id: this.loginService.getUser().id
                                };
                            }
                        });
                    this.subscriptions.add(this.createAttachment$);
                }
            } else {
                this.postService.emitUpdatedPost(this.post);
                this.fileToAdd = [];
                this.editionMode = false;
                this.lastLog = {
                    fullName:
                        this.loginService.getUser().lastname.toUpperCase() +
                        ' ' +
                        this.loginService.getUser().firstname,
                    fullDate:
                        new Date().toLocaleDateString('fr-FR') +
                        ' à ' +
                        new Date().toLocaleTimeString('fr-FR'),
                    id: this.loginService.getUser().id
                };
            }
        });
        this.subscriptions.add(this.updatePost$);
    }

    quotePost() {
        this.quote.emit(!this.isQuote ? this.post : null);
    }

    formatMessage(msg: string): string {
        let openBold = false,
            openItalic = false;

        while (msg.indexOf('*') > -1 || msg.indexOf('_') > -1) {
            if (msg.indexOf('*') > -1) {
                msg = msg.replace('*', openBold ? '</strong>' : '<strong>');
                openBold = !openBold;
            }
            if (msg.indexOf('_') > -1) {
                msg = msg.replace('_', openItalic ? '</em>' : '<em>');
                openItalic = !openItalic;
            }
        }
        if (openBold) {
            msg = msg + '</strong>';
        }
        if (openItalic) {
            msg = msg + '</em>';
        }

        return msg;
    }

    canWrite(): Boolean {
        return this.permissionService.canCreatePost(this.forum);
    }
}
