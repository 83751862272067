import { Component, Output, EventEmitter, OnDestroy, OnInit } from '@angular/core';

import { ForumService } from '../../services/forum.service';
import { ThemeService } from '../../services/theme.service';
import { SubthemeService } from '../../services/subtheme.service';
import { SubjectService } from '../../services/subject.service';

import { Forum } from '../../structures/forum';
import { Theme } from '../../structures/theme';
import { Subtheme } from '../../structures/subtheme';
import { ForumSubject } from '../../structures/subject';

import { ForumComponent } from './forum/forum.component';
import { ThemeComponent } from './theme/theme.component';
import { SubthemeComponent } from './subtheme/subtheme.component';
import { SubjectComponent } from './subject/subject.component';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

/**
 * Composant gérant l'affichage de la vue principal
 */
@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss'],
    standalone: false
})
export class MainComponent implements OnDestroy, OnInit {
    @Output() forumSelected: EventEmitter<number> = new EventEmitter();

    constructor(
        private forumService: ForumService,
        private themeService: ThemeService,
        private subthemeService: SubthemeService,
        private subjectService: SubjectService,
        private router: Router
    ) {}

    forum: Forum;
    theme: Theme;
    subtheme: Subtheme;
    subject: ForumSubject;
    disabled: boolean;
    searchTerm: string;
    private subscriptions = new Subscription();

    ngOnInit() {
        this.subscriptions.add(
            this.forumService.getSearchTerm().subscribe((searchTermFromService: string) => {
                this.searchTerm = searchTermFromService;
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    onActivate(componentReference: any) {
        if (componentReference instanceof ForumComponent) {
            this.subscriptions.add(
                componentReference.updateForum.subscribe((data: Forum) => {
                    this.forum = data;
                    this.theme = undefined;
                    this.subtheme = undefined;
                    this.subject = undefined;
                    this.forumSelected.emit(data.id);
                })
            );
        }

        if (componentReference instanceof ThemeComponent) {
            this.subscriptions.add(
                componentReference.updateTheme.subscribe((data: Theme) => {
                    if (!this.forum) {
                        this.forum = data.hierarchy.forum;
                    }
                    this.theme = data;
                    this.subtheme = undefined;
                    this.subject = undefined;
                    this.forumSelected.emit(data.id);
                })
            );
        }

        if (componentReference instanceof SubthemeComponent) {
            this.subscriptions.add(
                componentReference.updateSubtheme.subscribe((data: Subtheme) => {
                    if (!this.forum) {
                        this.forum = data.hierarchy.forum;
                    }
                    if (!this.theme) {
                        this.theme = data.hierarchy.theme;
                    }
                    this.subtheme = data;
                    this.subject = undefined;
                    this.forumSelected.emit(data.id);
                })
            );
        }

        if (componentReference instanceof SubjectComponent) {
            this.subscriptions.add(
                componentReference.updateSubject.subscribe((data: ForumSubject) => {
                    if (!this.forum) {
                        this.forum = data.hierarchy.forum;
                    }
                    if (!this.theme) {
                        this.theme = data.hierarchy.theme;
                    }
                    if (!this.subtheme) {
                        this.subtheme = data.hierarchy.subtheme;
                    }
                    this.subject = data;
                    this.forumSelected.emit(data.id);
                })
            );
        }
    }

    refreshChildren(): void {
        if (this.subject) {
            this.subjectService.refreshSubject(this.searchTerm);
        } else if (this.subtheme) {
            this.subthemeService.refreshSubtheme(this.searchTerm);
        } else if (this.theme) {
            this.themeService.refreshTheme(this.searchTerm);
        } else if (this.forum) {
            this.forumService.emitRefreshForum(this.searchTerm);
        }
    }

    refreshChildrenInterval(): void {
        if (!this.disabled) {
            this.disabled = true;
            this.refreshChildren();
            setTimeout(() => {
                this.disabled = false;
            }, 5000);
        }
    }
}
